/* eslint-disable no-restricted-globals */
export function transitionEndEventName() {
  const el = document.createElement("fakeelement");

  const transitions = {
    transition: "transitionend",
    OTransition: "oTransitionEnd",
    MozTransition: "transitionend",
    WebkitTransition: "webkitTransitionEnd"
  };
  const property = Object.keys(transitions).find(
    key => el.style[key] !== undefined
  );

  return transitions[property];
}

export function getClientHeight() {
  return Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  );
}

export function scrollPromise(elem, targetPosition = 0) {
  window.scrollTo({
    top: targetPosition,
    behavior: "smooth"
  });

  return new Promise(resolve => {
    const scrollHandler = () => {
      if (self.pageYOffset === targetPosition) {
        window.removeEventListener("scroll", scrollHandler);
        resolve();
      }
    };
    if (self.pageYOffset === targetPosition) {
      resolve();
    } else {
      window.addEventListener("scroll", scrollHandler);
      elem.getBoundingClientRect();
    }
  });
}
