<template>
  <ContactUs
    :heading="$t('yourChoices')"
    :open-button-text="$t('getQuote')"
    :before-send-callback="beforeSendCallback"
  >
    <template #pre-form>
      <div
        class="modal-spec w-100 flex-direction-column justify-content-between mb-1"
        style="padding: 20px"
      >
        <span class="d-flex justify-content-start techs flex-wrap">
          <span
            class="font-weight-normal"
            v-for="tech in technologies.filter(t => t.number)"
            :key="tech.name"
            >{{ tech.number }} {{ tech.name }}
            <i class="text-primary">|</i>
          </span>
        </span>
        <strong class="d-flex justify-content-start"
          >{{ $t("duration") }}
          <span style="padding: 0 0 0 5px" class="font-weight-normal">
            {{ $t("months", { duration }) }}</span
          ></strong
        >
      </div>
    </template>
  </ContactUs>
</template>

<script>
import ContactUs from "@/components/modals/ContactUs";

export default {
  name: "TeamErweiternModal",
  components: { ContactUs },
  props: {
    technologies: {
      type: Array,
      default: () => []
    },
    duration: {
      type: Number,
      required: true
    }
  },
  methods: {
    beforeSendCallback(answers) {
      const duration = this.$t("months", { duration: this.duration });
      const techs = this.technologies
        .filter(t => t.number)
        .map(t => `${t.name}: ${t.number}`)
        .join(", ");

      return {
        ...answers,
        duration,
        techs
      };
    }
  },
  i18n: {
    messages: {
      de: {
        success: `<strong>Vielen Dank für Ihre Nachricht.</strong><span>Wir melden uns bei Ihnen!</span>`,
        yourChoices: `Ihre Zusammenstellung`,
        duration: "DAUER",
        months: "{duration} MONATE",
        send: "Anfragen",
        getQuote: "Angebot anfordern"
      },
      en: {
        success: `<strong>Thank you for your message.</strong><span>We will get back to you!</span>`,
        yourChoices: "Your summary",
        duration: "DURATION",
        months: "{duration} MONTHS",
        send: "requests",
        getQuote: "Get Quote"
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/imports";

.techs {
  color: $second-color;
  margin-bottom: $font-size-base;
  & > span {
    font-size: $font-size-sm;
    flex-shrink: 0;
    & > i {
      font-family: sans-serif;
      display: inline;
      margin: 0 0.25em;
    }

    &:last-child {
      & > i {
        display: none;
      }
    }
  }
}

.modal-spec {
  height: 118px;
  background: $modal-spec-color;
  strong {
    font-size: $font-size-sm;
  }
}
</style>
