<template>
  <div :class="[$style.track]" class="mx-1 mx-md-4 position-relative">
    <div
      ref="segmentContainer"
      :class="[$style.segments]"
      class="position-absolute d-flex w-100 flex-row"
    >
      <span
        class="d-inline-block position-relative"
        v-for="s in segments"
        :ref="`segment-${s}`"
        :key="s"
        :class="[$style.segment, s <= selectedValue && $style.active]"
        :style="segmentStyle"
      >
        <span
          class="position-absolute"
          v-if="highlighted.includes(s)"
          :class="$style.highlighted"
          :data-length="transformMonths(s)"
        />
      </span>
    </div>
    <ProjectDurationPickerSledge v-model="selectedValue" />
  </div>
</template>

<script>
import ProjectDurationPickerSledge from "@/components/team-erweitern/ProjectDurationPickerSledge";

export default {
  name: "ProjectDurationPicker",
  components: { ProjectDurationPickerSledge },
  props: {
    value: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      segments: 24,
      jumps: 1,
      highlighted: [1, 6, 12, 18, 24]
    };
  },
  computed: {
    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    segmentStyle() {
      return {
        flex: `1 1 ${100 / this.segments}%`
      };
    }
  },
  methods: {
    transformMonths(months) {
      switch (months) {
        case 1:
        case 6:
        case 12:
        case 18:
        case 24:
          return this.$t(`duration.${months}`);
        default:
          return "";
      }
    }
  },
  i18n: {
    messages: {
      de: {
        duration: {
          "1": "1 MONAT",
          "6": "6 MONATE",
          "12": "1 JAHR",
          "18": "1.5 JAHRE",
          "24": "2+ JAHRE"
        }
      },
      en: {
        duration: {
          "1": "1 month",
          "6": "6 months",
          "12": "1 year",
          "18": "1.5 years",
          "24": "2+ years"
        }
      }
    }
  }
};
</script>

<style module lang="scss">
@import "../../styles/imports";

$paddingX: $spacer / 4;

.track {
  border-bottom: 3px solid $text-color-dark;
  padding: 0 $paddingX;
}

.segments {
  top: 0;
  left: 0;
  right: -1px;

  .segment {
    height: 3px;
    background-color: $text-color-dark;
    transition: background 0.2s ease-in;

    &:after {
      content: "";
      position: absolute;
      top: -4px;
      right: -1px;
      left: 0;
      bottom: -4px;
      border-right: 1px solid $text-color-dark;
    }

    &:first-child:after {
      border-left: 1px solid $text-color-dark;
    }

    &:last-child:after {
      border-right: 1px solid $text-color-dark;
    }

    &.active {
      background-color: $accent;
    }

    .highlighted {
      top: -6px;
      right: -7px;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: $accent;
      z-index: 1;

      &::after {
        content: attr(data-length);
        white-space: nowrap;
        font-weight: bold;
        font-size: 0.7em;
        position: absolute;
        transform: translateY(1em) translateX(-40%);
      }
    }
  }
}

.rangeInput {
  width: 100%;
  position: absolute;
  left: 0;
  appearance: none;
  background: $colorVerticalLines;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}
</style>
