<template>
  <div
    ref="dragHandle"
    class="d-flex flex-row position-absolute"
    :class="[$style.root, moveToNextStop && $style.animatedMove]"
    :style="style"
    @mousedown.left="startMoving"
    @touchstart="startMoving"
  >
    <img
      style="width: 16px; pointer-events:none"
      class="d-inline-block"
      src="@/assets/img/team/additionally/arrow_left.svg"
      alt="Pfeil Links"
      :class="[$style.arrow]"
    />
    <span class="text-center position-relative" :class="[$style.label]">{{
      valueText
    }}</span>
    <img
      style="width: 16px; pointer-events:none"
      class="d-inline-block"
      src="@/assets/img/team/additionally/arrow_right.svg"
      alt="Pfeil Rechts"
      :class="[$style.arrow]"
    />
  </div>
</template>

<script>
import { transitionEndEventName } from "@/utils/animationHelper";

export default {
  name: "ProjectDurationPickerSledge",
  props: {
    value: {
      type: Number
    }
  },
  data() {
    return {
      isMove: false,
      moveToNextStop: false,
      drag: {
        initialX: 0,
        currentX: 0,
        offsetX: 0
      }
    };
  },
  computed: {
    valueText() {
      if ([1, 6, 12, 18, 24].includes(this.value)) {
        return this.$t(`duration.${this.value}`);
      }
      return this.$t("duration.general", { number: this.value });
    },
    style() {
      return {
        transform: `translateX(${this.drag.currentX}px)`
      };
    }
  },
  mounted() {
    this.alignWithElement();
  },
  methods: {
    startMoving(e) {
      if (e.type === "touchstart") {
        this.drag.initialX = e.touches[0].clientX - this.drag.offsetX;
      } else {
        this.drag.initialX = e.clientX - this.drag.offsetX;
      }

      this.isMove = true;
      // instead of relying on events of the components root element
      // we allow the mouse move freely on the complete page
      // advantage: mouse can also leave the container.
      // as long as the mouse button is clicked, dragging works.
      document.addEventListener("mousemove", this.move);
      document.addEventListener("touchmove", this.move);
      document.addEventListener("mouseup", this.stopMoving);
      document.addEventListener("touchend", this.stopMoving);
    },

    move(e) {
      if (!this.isMove) {
        return;
      }
      let newPosition = 0;
      if (e.type === "touchmove") {
        newPosition = e.touches[0].clientX - this.drag.initialX;
      } else {
        newPosition = e.clientX - this.drag.initialX;
      }
      this.moveToNextStop = false;
      // only when new sledge position is within bounds its allowed to proceed

      this.drag.currentX = newPosition;
      this.drag.offsetX = this.drag.currentX;

      const segmentBounds = this.getBoundsOfSegments();
      const { left, width } = this.$refs.dragHandle.getBoundingClientRect();
      const center = left + width / 2;
      // finds number of the last segment, which is "passed" by the handle
      const indexOfLastSmaller =
        segmentBounds.filter(el => el <= center).length || 1;
      this.$emit("input", indexOfLastSmaller);
    },

    stopMoving() {
      this.isMove = false;
      this.drag.initialX = this.drag.currentX;
      document.removeEventListener("mousemove", this.move);
      document.removeEventListener("touchmove", this.move);
      document.removeEventListener("mouseup", this.stopMoving);
      document.removeEventListener("touchend", this.stopMoving);
      this.alignWithElement();
    },

    /**
     * Gets the segment for the current value
     * Calculates its bounding box
     * Moves sledge to this element
     */
    alignWithElement() {
      this.moveToNextStop = true;
      const [currentEl] = this.$parent.$refs[`segment-${this.value}`];
      const rect = currentEl.getBoundingClientRect();
      const myWidth = this.$el.offsetWidth;
      this.drag.currentX = this.value * rect.width - myWidth / 2;
      this.drag.initialX = this.drag.currentX;
      this.drag.offsetX = this.drag.currentX;
      const stopAnimationEvent = () => {
        this.moveToNextStop = false;
        this.$refs.dragHandle.removeEventListener(
          transitionEndEventName(),
          stopAnimationEvent
        );
      };
      this.$refs.dragHandle.addEventListener(
        transitionEndEventName(),
        stopAnimationEvent
      );
    },

    /**
     *  gets all segments of the track
     *  calculates the right-bound of each segment
     */
    getBoundsOfSegments() {
      return Object.keys(this.$parent.$refs)
        .filter(key => key.startsWith("segment-"))
        .map(key => {
          const [el] = this.$parent.$refs[key];
          const rect = el.getBoundingClientRect();
          return rect.left + rect.width;
        });
    },

    /**
     * fetches the bounding box of the segment container
     * calculates the width of the sledge
     * returns min/max values for the sledge position
     * @returns {{left: number, right: number}}
     */
    getBoundsOfSledge() {
      const {
        left,
        right
      } = this.$parent.$refs.segmentContainer.getBoundingClientRect();
      const { width } = this.$refs.dragHandle.getBoundingClientRect();
      return {
        left: left - width,
        right: right - width
      };
    }
  },
  i18n: {
    messages: {
      de: {
        duration: {
          "1": "1 Monat",
          "6": "0,5 Jahre",
          "12": "1 Jahr",
          "18": "1,5 Jahre",
          "24": "2+ Jahre",
          general: "{number} Monate"
        }
      },
      en: {
        duration: {
          "1": "1 month",
          "6": "0,5 years",
          "12": "1 year",
          "18": "1,5 years",
          "24": "2+ years",
          general: "{number} months"
        }
      }
    }
  }
};
</script>

<style module lang="scss">
@import "../../styles/imports";

.root {
  top: -65px;
  left: 0;
  user-select: none;
  cursor: pointer;

  &.animatedMove {
    transition: transform 0.2s ease-in;
  }
}

.label {
  box-shadow: $box-calc-mobile;
  width: 120px;
  height: 45px;
  font-size: $font-size-sm;
  padding: 12px 2px;
  color: $second-color;
  background-color: $body-bg;
  user-select: none;
  cursor: pointer;
  margin: 0 8px;

  &:after {
    content: "";
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    margin-top: 22px;
    margin-left: -8px;
    border-style: solid;
    position: absolute;
    border-width: 8px 8px 0 8px;
    border-color: $body-bg transparent transparent transparent;
  }
}
</style>
