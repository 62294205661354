<template>
  <div class="container">
    <div class="calculator-mobile mb-3 px-1">
      <h3 class="py-2 text-center text-primary m-0">
        {{ $t("texts.0.title") }}
      </h3>

      <div class="calculator-name pb-2">
        <span
          v-for="(item, index) in technology"
          :class="mobileActiveTech.name === item.name ? 'active' : ' '"
          class="d-inline ml-1"
          @click="mobileActiveTech = item"
          :key="index"
          >{{ item.name }}</span
        >
      </div>

      <div class="calculator-counter align-items-center">
        <button
          class="border-0"
          @click="
            mobileActiveTech.number -= mobileActiveTech.number > 0 ? 1 : 0
          "
        >
          <img
            src="@/assets/img/team/additionally/calculator_remove.svg"
            :alt="$t('subCoder')"
          />
        </button>

        <div class="calculator-develop align-items-center">
          <img
            style="width: 45px; height: 45px"
            src="@/assets/img/team/additionally/user.svg"
            :alt="$t('coderIcon')"
          />
          <span class="d-block text-center h-100">
            {{ mobileActiveTech.number }}
          </span>
        </div>

        <button
          class="border-0"
          @click="
            mobileActiveTech.number += mobileActiveTech.number < 5 ? 1 : 0
          "
        >
          <img
            src="@/assets/img/team/additionally/calculator_add.svg"
            :alt="$t('addCoder')"
          />
        </button>
      </div>

      <h3 class="py-2 text-center text-primary m-0">
        {{ $t("texts.1.title") }}
      </h3>

      <div class="calculator-month">
        <select
          v-model="duration"
          class="calculator-month--container position-relative w-100 border-0 border-rad"
        >
          <option v-for="mb in mobileDuration" :key="mb" :value="mb">{{
            $t(`duration.${mb}`)
          }}</option>
        </select>
      </div>

      <TeamErweiternModal :technologies="technology" :duration="duration" />
    </div>
  </div>
</template>

<script>
import TeamErweiternModal from "@/components/team-erweitern/TeamErweiternModal";

export default {
  name: "TeamCalcMobile",
  components: { TeamErweiternModal },
  props: {
    technology: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isContactModal: false,
      duration: 1,
      mobileActiveTech: {},
      mobileList: false,
      mobileDuration: [1, 6, 12, 18, 24]
    };
  },

  methods: {},
  watch: {
    technology([defaultTechnology]) {
      if (defaultTechnology) {
        this.mobileActiveTech = defaultTechnology;
      }
    }
  },
  i18n: {
    messages: {
      de: {
        texts: [
          { title: `1. Technologie und Teamstärke wählen`, text: `` },
          { title: `2. Projektdauer schätzen`, text: `` }
        ],
        subCoder: "Programmierer Entfernen",
        addCoder: "Programmierer Hinzufügen",
        coderIcon: "Programmierer",
        duration: {
          "1": "1 MONAT",
          "6": "6 MONATE",
          "12": "1 JAHR",
          "18": "1.5 JAHRE",
          "24": "2+ JAHRE"
        }
      },
      en: {
        texts: [
          { title: `1. Choose technology and team strength`, text: `` },
          { title: `2. Estimate project duration`, text: `` }
        ],
        subCoder: "Remove coder",
        addCoder: "Add coder",
        coderIcon: "Coder",
        duration: {
          "1": "1 month",
          "6": "6 months",
          "12": "1 year",
          "18": "1.5 years",
          "24": "2+ years"
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../styles/imports";
h3 {
  font-size: $font-size-medium;
}
.active {
  font-weight: bold;
  padding: 0 0 5px 0;
  border-bottom: 3px solid $accent;
}
.calculator-mobile {
  display: block;
  background-color: $body-bg;
  box-shadow: $box-shadow-card;
  @include breakpoint(md) {
    display: none;
  }
}
.calculator-counter {
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  button {
    cursor: pointer;
    width: 40px;
    height: 40px;
    box-shadow: $box-calc-mobile;
    border-radius: 50%;
    background-color: $body-bg;
    img {
      margin: -5px 0 0 0;
      width: 20px;
      height: 20px;
    }
  }
}
.calculator-name {
  cursor: pointer;
  overflow-y: scroll;
  user-select: none;
  span {
    cursor: pointer;
    font-size: $font-size-sm;
  }
}
.calculator-name::-webkit-scrollbar-track {
  background-color: $body-bg;
}
.calculator-name::-webkit-scrollbar {
  height: 0px;
  background-color: $body-bg;
}
.calculator-develop {
  justify-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  span {
    font-size: $font-size-base;
    border-bottom: 1px solid $black;
    width: 20px;
  }
}
.calculator-month--container {
  background-color: transparent;
  outline: none;
  background: url("./../../assets/img/team/additionally/arrow-select.svg")
    no-repeat 95%;
  background-size: 5%;
  padding: 15px;
  border-radius: 5px;
  box-shadow: $box-calc-mobile;
  appearance: none;
}
</style>
