<template>
  <div class="calculator-hidden" :class="$style.calculatorContainer">
    <div class="container">
      <div :class="$style.calculator" class="mb-2">
        <h3 class="py-2 text-center text-primary m-0">
          {{ $t("texts.0.title") }}
        </h3>

        <div class="v-grid mb-3">
          <div v-for="(tech, index) in technology" :key="index" class="col">
            <TechnologyPicker :technology="tech" />
          </div>
        </div>

        <div class="block-background">
          <h3 class="pb-2 mb-2 text-center text-primary">
            {{ $t("texts.1.title") }}
          </h3>
          <div class="pb-3">
            <ProjectDurationPicker v-model="duration" />
          </div>
        </div>

        <TeamErweiternModal :technologies="technology" :duration="duration" />
      </div>
    </div>
  </div>
</template>

<script>
import TechnologyPicker from "@/components/team-erweitern/TechnologyPicker";
import ProjectDurationPicker from "@/components/team-erweitern/ProjectDurationPicker";
import TeamErweiternModal from "@/components/team-erweitern/TeamErweiternModal";

export default {
  name: "TeamCalcDesktop",
  components: {
    TechnologyPicker,
    ProjectDurationPicker,
    TeamErweiternModal
  },
  props: {
    technology: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isContactModal: false,
      duration: 1
    };
  },

  methods: {},

  i18n: {
    messages: {
      de: {
        texts: [
          { title: `1. Technologie und Teamstärke wählen`, text: `` },
          { title: `2. Projektdauer schätzen`, text: `` }
        ]
      },
      en: {
        texts: [
          { title: `1. Choose technology and team strength`, text: `` },
          { title: `2. Estimate project duration`, text: `` }
        ]
      }
    }
  }
};
</script>

<style module lang="scss">
@import "../../styles/imports";
.calculatorContainer {
  background: url("../../assets/img/team/additionally/bg.png") no-repeat top
    center;
  background-size: contain;
  min-height: 500px;
  padding-top: 10%;
}

.calculator {
  background-color: $body-bg;
  box-shadow: $box-shadow-card;
}
</style>

<style scoped lang="scss">
@import "../../styles/imports";
h3 {
  @include breakpoint(md) {
    padding: 50px 0 30px 0;
    line-height: $line-height-base;
    font-size: $font-size-md;
  }
  @include breakpoint(lg) {
    font-size: $font-size-md;
  }
}
.calculator-hidden {
  display: none;
  @include breakpoint(md) {
    display: block;
  }
}
.block-background {
  z-index: -1;
  background-color: $team-card-bg;
  padding: 30px 0 40px;
}
</style>
