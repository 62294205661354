<template>
  <WhitePageLayout :page-icon="pageIcon" hide-consultation>
    <div class="container">
      <StoryText class="px-1 pt-md-2 pt-4 px-md-3 mb-3 mt-md-4 text-center">
        <div v-html="$t('texts.0.text')"></div>
      </StoryText>
    </div>
    <TeamCalcDesktop :technology="technology" />
    <TeamCalcMobile :technology="technology" />
  </WhitePageLayout>
</template>

<script>
import TeamCalcDesktop from "@/components/team-erweitern/TeamCalcDesktop";
import TeamCalcMobile from "@/components/team-erweitern/TeamCalcMobile";

import { metaInfo } from "@/utils/titleHelper";
import WhitePageLayout from "@/components/layouts/WhitePageLayout";
import pageIcon from "@/assets/img/logo/network.svg";
import StoryText from "@/components/story/StoryText";

import nodejs from "@/assets/img/team/technology/Nodejs.png";
import vuejs from "@/assets/img/team/technology/Vuejs.png";
import react from "@/assets/img/team/technology/React.png";
import laravel from "@/assets/img/team/technology/Laravel.png";
import php from "@/assets/img/team/technology/Php.png";
import wordpress from "@/assets/img/team/technology/Wordpress.png";
import docker from "@/assets/img/team/technology/Docker.png";

export default {
  name: "TeamErweitern",
  components: {
    StoryText,
    WhitePageLayout,
    TeamCalcDesktop,
    TeamCalcMobile
  },
  data() {
    return {
      pageIcon,
      duration: 1,
      technology: []
    };
  },

  async created() {
    this.technology = [
      {
        icon: nodejs,
        name: "NODE.JS",
        number: 0,
        active: true
      },
      {
        icon: vuejs,
        name: "VUE.JS",
        number: 0,
        active: false
      },
      {
        icon: react,
        name: "REACT",
        number: 0,
        active: false
      },
      {
        icon: laravel,
        name: "LARAVEL",
        number: 0,
        active: false
      },
      {
        icon: php,
        name: "PHP",
        number: 0,
        active: false
      },
      {
        icon: wordpress,
        name: "WORDPRESS",
        number: 0,
        active: false
      },
      {
        icon: docker,
        name: "DOCKER",
        number: 0,
        active: false
      }
    ];
  },
  metaInfo() {
    return metaInfo(this.$t("title"), this.$t("descr"));
  },

  i18n: {
    messages: {
      de: {
        title: "Wir performen gemeinsam mit Ihrem Entwickler-Team",
        descr:
          "Entwickler gewählt und einfach gebucht ✓ – Bei Auslastungsspitzen und knappen Deadlines unterstützen wir Sie mit unserem Team.",
        texts: [
          {
            title: "",
            text: `Wir bei MEDIAFORMAT wissen, dass die Suche nach erfahrenen Web-Entwicklern eine große Herausforderung sein kann. Gute IT-Fachkräfte sind oft nur schwer zu finden.<strong> Deshalb unterstützen wir Sie mit unseren Experten bei Ihren Projektarbeiten</strong><span> – vertraulich und professionell.</span>`
          }
        ]
      },
      en: {
        title: "We perform together with your development team",
        descr:
          "Developer selected and simply booked ✓ - With workload peaks and tight deadlines we support you with our team.",
        texts: [
          {
            title: "",
            text: `Here at MEDIAFORMAT, we know that finding experienced web developers can be a big challenge. Good IT professionals are often hard to find.<strong>Therefore our experts support you with your projects</strong><span> – confidential and professional.</span>`
          }
        ]
      }
    }
  }
};
</script>
