<template>
  <div
    class="d-flex justify-content-center align-items-center text-center"
    :class="[$style.techPicker]"
    @mouseenter="isHover = true"
    @mouseleave="isHover = false"
  >
    <button
      class="d-flex position-relative justify-content-center align-items-center border-0"
      :class="subtractClasses"
      @click="subtract"
    >
      <img
        src="@/assets/img/team/additionally/calculator_remove.svg"
        :alt="$t('subCoder')"
      />
    </button>
    <div
      class="d-flex position-relative overflow-hidden align-items-center flex-column justify-content-start"
      :class="[$style.techContainer, selectedClass]"
    >
      <div
        class="overflow-hidden d-flex justify-content-center align-items-center text-center"
        :class="[$style.techLogo, selectedClass]"
      >
        <img
          class="d-inline-block h-auto"
          :src="technology.icon"
          :alt="technology.name"
        />
      </div>
      <h4
        class="d-inline-block text-center"
        :class="[$style.techTitle, selectedClass]"
      >
        {{ technology.name }}
      </h4>
      <div
        class="d-flex overflow-hidden"
        :class="[$style.selectedContainer, selectedClass]"
      >
        <div
          class="d-flex align-items-end h-auto"
          :class="[$style.worker, selectedClass]"
        >
          <img
            class="w-100 h-auto"
            src="@/assets/img/team/additionally/user.svg"
            :alt="$t('coderIcon')"
          />
        </div>
        <div
          class="d-flex align-items-end justify-content-center h-100"
          :class="[$style.counter, selectedClass]"
        >
          <span>
            {{ technology.number }}
          </span>
        </div>
      </div>
    </div>
    <button
      class="d-flex position-relative justify-content-center align-items-center border-0"
      :class="addClasses"
      @click="add"
    >
      <img
        src="@/assets/img/team/additionally/calculator_add.svg"
        :alt="$t('addCoder')"
      />
    </button>
  </div>
</template>
<script>
import { $mqMixin } from "@/utils/mixins";

export default {
  name: "TechnologyPicker",
  mixins: [$mqMixin],
  props: {
    technology: {
      required: true
    }
  },
  data() {
    return {
      isHover: false
    };
  },
  computed: {
    isSelected() {
      return this.technology.number > 0 || this.isHover;
    },
    selectedClass() {
      if (this.isSelected) {
        return this.$style.selected;
      }
      if (["xs", "sm"].includes(this.mq)) {
        return this.$style.selected;
      }
      return "";
    },
    subtractClasses() {
      return [
        this.$style.btn,
        this.selectedClass,
        this.technology.number === 0 && this.$style.disabled
      ];
    },
    addClasses() {
      return [
        this.$style.btn,
        this.selectedClass,
        this.technology.number >= 5 && this.$style.disabled
      ];
    }
  },
  methods: {
    add() {
      if (this.technology.number < 5) {
        this.technology.number += 1;
      }
    },
    subtract() {
      if (this.technology.number > 0) {
        this.technology.number -= 1;
      }
    }
  },
  i18n: {
    messages: {
      de: {
        subCoder: "Programmierer Entfernen",
        addCoder: "Programmierer Hinzufügen",
        coderIcon: "Programmierer"
      },
      en: {
        subCoder: "Remove coder",
        addCoder: "Add coder",
        coderIcon: "Coder"
      }
    }
  }
};
</script>
<style module lang="scss">
@import "../../styles/imports";

$paddingX: 16px;
$transitionDuration: 0.2s;
$cubeSize: 120px;
$innerSize: $cubeSize - 2 * $paddingX;

.techPicker {
  justify-items: center;
  padding: 10px;
  font-size: $font-size-base;
  cursor: pointer;
}

.techContainer {
  width: $cubeSize;
  height: $cubeSize;
  padding-bottom: 10px;
  transition: background $transitionDuration ease-in;
  z-index: 1;
  background: $body-bg;

  &.selected {
    background: $accent;
    z-index: 1;
  }
}

.techLogo {
  width: $innerSize;
  height: $innerSize;
  transition: height $transitionDuration ease-in,
    opacity $transitionDuration ease-in;

  img {
    max-height: 65px;
    max-width: 100%;
  }

  &.selected {
    opacity: 0;
    height: 0;
  }
}

.techTitle {
  font-size: $font-size-medium;
  color: $black;
  font-weight: $font-weight-light;
  margin: 0;
  width: 100%;
  border-bottom: 3px solid $accent;
  transition: width $transitionDuration ease-in,
    border-bottom-color $transitionDuration ease-in margin $transitionDuration
      ease-in;
  padding: 10px 0;

  &.selected {
    width: auto;
    min-width: $innerSize;
    margin: 5px $paddingX;
    border-bottom-color: $black;
  }
}

.btn {
  box-shadow: $box-calc-mobile;
  background-color: $body-bg;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 30px;
  opacity: 0;
  margin: 0 10px;
  z-index: 0;
  transition: transform $transitionDuration ease-in,
    opacity $transitionDuration ease-in;

  &:first-child {
    transform: translateX(80px);
  }

  &:last-child {
    transform: translateX(-80px);
  }

  &.selected {
    opacity: 1;
    transform: translateX(0);
  }

  &.disabled {
    opacity: 0.3;
  }

  img {
    width: 71%;
  }

  &:hover {
    opacity: 0.6;
  }

  &:active,
  &:focus {
    outline: none;
  }
}

.selectedContainer {
  width: $innerSize;
  height: 0;
  flex-direction: row;
  transition: height $transitionDuration ease-in;
  &.selected {
    height: 50px;
  }
}

.worker,
.counter {
  flex: 1 1 50%;
}

.counter {
  color: $black;
  span {
    vertical-align: top;
    padding: 5px 3px;
    border-bottom: 3px solid $black;
  }
}
</style>
